<template>
  <list-condition-template ref="table" :rows="rows" v-model:listQuery="listQuery"
   :defaultSort="{prop:'updateTime',order:'descending'}" :total="total"
    @page-info-change="handlePageInfoChange" :index-column="true">
    <template #condition>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" placeholder="型号/物料代码" v-model="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
        <!-- <el-button class="filter-item" type="primary" v-waves icon="el-icon-download" @click="handleImport">导入</el-button>
         <el-button class="filter-item" v-show="multiSelected.length" @click="handleBath(handleDelete)" type="danger" icon="el-icon-close" v-waves>批量删除</el-button>
        <el-button type="text" icon="el-icon-document" @click="downloadTemplate">下载导入模板</el-button> -->
      </el-form-item>
      <el-row>
        <el-button class="filter-item" @click="handleCreate" type="success" size="mini" icon="el-icon-plus" v-waves>新建</el-button>
      </el-row>
    </template>
    <template #columns>
      <el-table-column label="物料代码" align="center" property="materialCode">
        <template  #default="scope">
          <span>{{scope.row.materialCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="型号" min-width="200px" align="center" property="name">
        <template  #default="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型" align="center" property="type.title">
        <template  #default="scope">
          <el-tag :type="showFilter(scope.row)">{{scope.row.type && scope.row.type.title || ''}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="品类" align="center" property="category.name">
        <template  #default="scope">
          <span>{{scope.row.category && scope.row.category.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="品类代码" align="center" property="category.categoryCode">
        <template  #default="scope">
          <span>{{scope.row.category && scope.row.category.categoryCode || ''}}</span>
        </template>
      </el-table-column>
      <el-table-column label="单位"  align="center" property="unit">
        <template  #default="scope">
          <span>{{scope.row.unit}}</span>
        </template>
      </el-table-column>
      <el-table-column label="联数"  align="center" property="page">
        <template  #default="scope">
          <span>{{scope.row.page}}</span>
        </template>
      </el-table-column>
      <el-table-column  align="center" label="启用状态" width="100px">
        <template  #default="scope">
          <el-tag :type="showEnabled(scope.row.enable)">{{scope.row.enable && scope.row.enable==='YES' ? '启用' : '停用'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="添加时间" min-width="150" align="center" sortable="custom" property="createTime">
        <template  #default="scope">
          <span>{{scope.row.createTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" min-width="150" align="center" sortable="custom" property="updateTime">
        <template  #default="scope">
          <span>{{scope.row.updateTime}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="详情" min-width="150" class-name="fixed-width">
        <template  #default="scope">
          <el-button size="mini" type="primary" @click="handleInvestigateDevice(scope.row)" v-waves>编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-waves>删除</el-button>
        </template>
      </el-table-column>
    </template>
    <el-dialog :title="title" v-model="showDialog" @closed="handleModleEditDialogClose" @open="getAllCategory" destroy-on-close>
        <el-form size="mini" ref="form" :model="modelForm" :rules="formRules" label-width="120px" status-icon>
          <el-form-item label="物料代码" prop="materialCode">
            <el-input v-model.trim="modelForm.materialCode" :minlength="9" :maxlength="9" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="设备型号" prop="name">
            <el-input v-model.trim="modelForm.name" :maxlength="50" show-word-limit @change="findCategory()"></el-input>
          </el-form-item>
          <el-form-item label="设备类型" prop="type.value">
            <el-select  v-model.trim="modelForm.type.value" :disabled="!!id">
              <el-option v-for="item in types" :key="item.value" :label="item.title" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="成品品类" prop="category.id" v-if="isHomeAppliances" :rules="{ required: isHomeAppliances, message: '小家电类型时请选择成品品类', trigger: 'blur'}">
            <el-select v-model="modelForm.category" value-key="id" :disabled="!!id||!isHomeAppliances" clearable>
              <el-option v-for="item in categories" :key="item.id" :label="item.name" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品类代码" prop="category.categoryCode" v-if="isHomeAppliances">
            <el-input disabled v-model="modelForm.category.categoryCode" :maxlength="20" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="计量单位" prop="unit">
            <el-input v-model.trim="modelForm.unit" :maxlength="10" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="对应联数" prop="page">
            <el-input disabled v-model.number="modelForm.page" :maxlength="1" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="是否启用" prop="enable">
            <el-radio v-model="modelForm.enable" label="YES">是</el-radio>
            <el-radio v-model="modelForm.enable" label="NO">否</el-radio>
          </el-form-item>
          <el-form-item>
          <el-button v-if="id" type="primary" :loading="loading" @click="handleSubmit(true)" v-waves>保存</el-button>
          <el-button v-if="!id" type="primary" :loading="loading" @click="handleSubmit(true)" v-waves>保存并继续</el-button>
          <el-button v-if="!id" type="primary" :loading="loading" @click="handleSubmit(false)" v-waves>保存并退出</el-button>
          <el-button v-waves @click="handleCancel">取消</el-button>
        </el-form-item>
        </el-form>
      <!-- </el-row> -->
    </el-dialog>
  </list-condition-template>

</template>

<script>
import modelApi from '@/apis/model/model'
import commonApi from '@/apis/common'
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'

export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate
  },
  data () {
    return {
      rows: null,
      total: 0,
      listQuery: {
        condition: {
          keyword: undefined
        },
        pageInfo: {
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      },
      multiSelected: [],
      title: '',
      showDialog: false,
      id: null,
      loading: false,
      isHomeAppliances: false,
      modelForm: {
        id: null,
        materialCode: '',
        name: '',
        page:null,
        unit:'TAI',
        category: {},
        type: { value: null },
        enable: 'YES'
      },
      types:[
        {value:'KITCHEN_APPLIANCE', title:'厨电'},
        {value:'HOME_APPLIANCES', title:'小家电'}
      ],
      categories:[],
      formRules: {
        materialCode: [
          { required: true, message: '请输入物料代码', trigger: 'blur' },
          {
            pattern: /^[0-9A-Za-z]{9}$/,
            message: '只能是9位数字、字母或数字和字母的组合'
          },
          { 
            validator: (rule, value, callback) => {
              commonApi.verifyMaterialCode(value, this.id).then(res => {
                if (!res.data) {
                  callback(new Error('物料代码:' + value + '已被使用，请重新输入'))
                } else {
                  callback()
                }
              }).catch(() => this.loading = false)
            }
          }
        ],
        name: [
          { required: true, message: '请输入型号名称', trigger: 'blur' },
          // {
          //   pattern:/^[a-zA-Z0-9\u4e00-\u9fa5_\-]+$/,
          //   message:"只能是数字、字母、汉字和下划线、短横线"
          // },
          { validator: (rule, value, callback) => {
            commonApi.verifyModelName(value, this.id).then(res => {
              if (!res.data) {
                callback(new Error('型号名称:' + value + '已被使用，请重新输入'))
              } else {
                callback()
              }
            }).catch(() => this.loading = false)
          },
          trigger: 'blur'
          }],
        'type.value':[
          { required: true, message: '请选择设备类型', trigger: 'blur' },
        ],
        unit: [{
          required: true,
          message: '请输入单位',
          trigger: 'blur'
        }],
        enable: [{
          required: true,
          message: '请选择是否启用',
          trigger: 'blur'
        }]
      }
    }
  },
  computed: {

  },
  watch:{
    'modelForm.type.value':{
      handler: function(newV,oldV){
        console.log('ve',oldV, newV)
        if(newV === 'HOME_APPLIANCES'){
          this.modelForm.page = 0
          this.isHomeAppliances = true
        }else{
          this.modelForm.page = 2
          this.isHomeAppliances = false
        }
      },
      deep:true
    }
  },
  methods: {
    showFilter (row) {
      return row.type && row.type.value === 'HOME_APPLIANCES' ? 'success' : 'warning'
    },
    showEnabled (enable) {
      return enable && enable === 'YES' ? 'success' : 'warning'
    },
    getAllCategory(){
      modelApi.getAllCategory().then(res => this.categories = res.data)
    },
    /**
     * 加载数据
     */
    getList () {
      modelApi
        .getPaged({ condition: this.listQuery.condition, pageInfo:this.listQuery.pageInfo })
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    /**
     * 搜索条件变更处理
     */
    handleFilter () {
      this.getList()
    },
    findCategory(){
      commonApi.getCategoryByModelName(this.modelForm.name).then(res => {
        if(res.data){
          this.modelForm.type.value = "HOME_APPLIANCES"
          this.modelForm.category = res.data
        }
        
      })
    },

    /**
     * 取消按钮处理
     */
    handleCancel () {
      this.showDialog = false
      this.resetForm()
      this.handleFilter()
    },
    /**
     * 分页信息变动事件处理
     * @param  {Object} pageInfo 分页信息
     */
    handlePageInfoChange () {
      this.getList()
    },
    /**
     * 新增按钮点击处理
     */
    handleCreate () {
      this.title = '新建型号'
      this.id = null
      this.showDialog = true
      this.resetForm()
    },
    handleModleEditDialogClose () {
      this.loading = false
      this.resetForm()
      this.handleFilter()
    },
    /**
     * 重置表单
     */
    resetForm () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
        this.modelForm.id = null
        this.modelForm.materialCode = ''
        this.modelForm.name = ''
        this.modelForm.page = null
        this.modelForm.unit = 'TAI'
        this.modelForm.categoryId = null
        this.modelForm.category = {},
        this.getAllCategory()
        this.modelForm.type={ value: null }
      })
    },
    /**
     * 批量处理函数
     */
    handleBath (fn) {
      // console.log(this.multiSelected)
      if (this.multiSelected.length === 0) {
        this.$message.warning('请先选择要处理的型号！')
      } else {
        fn(this.multiSelected)
      }
    },
    /**
     * 批量导入
     */
    handleImport () {
      // 批量导入功能还没写
    },
    /**
     * 下载导入模板
     */
    downloadTemplate () {
      // 还没写
    },
    /**
     * 查看详情点击处理
     * @param {*} row 参数
     */
    handleInvestigateDevice (row) {
      this.modelForm.category = {}
      Object.assign(this.modelForm, row)
      this.id = row.id
      this.title = '编辑型号'
      this.showDialog = true
    },
    /**
     * 多选
     * @param rows
     */
    onMultiSelected (rows) {
      this.multiSelected = rows
    },
    handleSubmit (goon) {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }
        this.loading = true
        this.modelForm.materialCode = this.modelForm.materialCode.toUpperCase()
        // this.modelForm.deviceType.title && delete this.modelForm.deviceType.title
        if(this.modelForm.type.value === 'KITCHEN_APPLIANCE'){
          this.modelForm.category = null
          this.modelForm.categoryId = null
        }else{
          this.modelForm.categoryId = this.modelForm.category.id
        }
        if (this.id) {
          modelApi
            .update(this.modelForm)
            .then(() => {
              this.showDialog = false
              this.resetForm()
              this.$message.success('修改型号成功！')
              this.handleFilter()
            }).catch(() => this.loading = false)
        } else {
          modelApi
            .insert(this.modelForm)
            .then(() => {
              this.resetForm()
              this.$message.success('添加型号成功！')
              if (!goon) {
                this.showDialog = false
                this.handleFilter()
              } else {
                this.loading = false
              }
            }).catch(() => this.loading = false)
        }
      })
    },

    /**
     * 批量删除
     * @param row
     */
    handleDelete (row) {
      this.$confirm('删除后无法恢复并会清除相应的关联关系,确定要删除该型号吗？', '警告', {
        type: 'warning'
      }).then(() => {
        modelApi.delete(row.id).then(res => {
          if (res.data) {
            this.handleFilter()
          } else {
            this.$message.error({
              title: '发生错误',
              message: res.msg === '' ? '删除该型号失败，请稍后重试！！！' : res.msg,
              duration: 0,
              position: 'bottom-right'
            })
          }
        })
      })
      this.handleFilter()
    }
  }
}
</script>

import api from '../api'
/**
 * 管理员用户相关api
 */
export class ModelApi extends api {
  getAllCategory(){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/category/all`,
      method: 'get'
    })
  }

}

export default new ModelApi('device/model')